import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {Typography} from "@mui/material";

export default function CircularIndeterminate(props) {
    return (
        <Box sx={{ display: 'flex',
            justifyContent: 'center', //Centered horizontally
            alignItems: 'center', //Centered vertically
            flex:1,
            height: 400,
            flexDirection: 'column',
            marginTop:25
        }}>
            <CircularProgress />
            <Typography >
                {props.message}
            </Typography>
        </Box>
    );
}
