import React, { PureComponent } from "react";
import ReactEcharts from "echarts-for-react";


const sumTotal = function(items, prop){
    return items.reduce( function(a, b){
        return parseFloat(a) + parseFloat(b[prop]);
    }, 0);
};

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function formatTip (params) {
    return params.name +" : "+ currencyFormat(params.value) + " ("+params.percent+"%)";
}

class Pie extends PureComponent {

    getOption = () => {

        const { salesData } = this.props;
        const data = [];
        if (salesData){
            let colors = {
                "Pub" : '#FF8C00',
                "Family": '#228B22',
                "Southgate": '#DC143C',
                "Longyard": '#ffd600'
            }
            Object.keys(salesData).forEach(function(key) {
                data.push({name: key, value: sumTotal(salesData[key], 'AMOUNT'),color: colors[key]});
            });
        }

        return ({
            title: {
                text: this.props.title,
                subtext: this.props.subtext,
                //x: "center"
            },
            aria: {
                decal: {
                    show:true
                }
            },
            tooltip: {
                trigger: "item",
                formatter: formatTip
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            series: [
                {
                    name: "Venue",
                    type: "pie",
                    radius: "60%",
                    center: ["50%", "50%"],
                    animationDuration: 50000,
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    }
                }
            ],
            color: this.props.colors
        })};

    render() {
        return (
            <ReactEcharts
                option={this.getOption()}
                style={{ height: "280px", width: "100%" }}
            />
        );
    }
}
export default Pie;
