import React, { Component }from 'react';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { withStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import LogoThePubGroup from '../../images/Logo-thepubgroup.png';
import LoginButton from "../../auth/LoginButton";
import login from "../../auth/login";

const styles = theme => ({
  paper: {
   marginTop: theme.spacing(8),
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
 },
 avatar: {
   margin: theme.spacing(1),
   backgroundColor: theme.palette.secondary.secondary,
 },
 form: {
   width: '100%', // Fix IE 11 issue.
   marginTop: theme.spacing(1),
 },
 submit: {
   margin: theme.spacing(3, 0, 2),
 },
});


let Login = inject("stores")(
    observer(
class Login extends Component {

    state = {
        userName: null,
        Password: null,
        message: null,
        error: false,
        userNameIcon:'action',
        PasswordIcon: 'action',
        selected: false,
        loading: false
    }

    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          this.signIn()
        }
    }

    _handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    _handleRedirection() {
        const { auth } = this.props.stores
        const user = auth.getAuthUser;
        let path = './Home'


        //auth.SignOut();

        if(this.props.location.hasOwnProperty('state')){
            if(this.props.location.state){
                if(this.props.location.state.hasOwnProperty('from')){
                    //path = this.props.location.state.from.pathname;
                }
            }
        }

        if(user){
            if(user.user.hasOwnProperty("VenueName")){
                path = {
                    pathname: '/Venue',
                    state: { venue:  user.user.VenueName}
                }
            }
            return <Redirect to={path} />
        }
    }

    _handleColor = (event) => {
        if(event.type === 'focus'){
            this.state.error
            ?  this.setState({[`${event.target.name}Icon`]: 'error'})
            :  this.setState({[`${event.target.name}Icon`]: 'primary'});
        } else if (event.type === 'blur') {
            this.setState({[`${event.target.name}Icon`]: 'action'});
        }
    }

    msg = (error) => {
      if(Array.isArray(error)){
        return error.map((item,i) => <li key={i}>{item}</li>)
      } else {
        if(error.hasOwnProperty('msg')){
          return <li>{error.msg}</li>
        }
      }
    }

  azureSignIn = (event) => {
    const { auth } = this.props.stores
    this.setState({loading: true})

    login().then( async (accessToken) => {
      auth.azureSignIn(accessToken).then(async (result) => {
        if (result) {
          switch (true) {
            case result.hasOwnProperty('error'):
              //login error username or password rejected
              this.setState({message: this.msg(result.error), error: true, loading: false})
              break;
            case result.hasOwnProperty('token'):
              //login success
              break;
            default:
              //unknown error something unexpected was return from api
              this.setState({message: result.message ? result.message : 'Something unexpected was return', error: true, loading: false})
          }
        } else {
          //couldnt connect to the API server
          this.setState({message: 'Server Error cannot connect to server', error: false, loading: false})
        }
      })
    })
  }

    signIn = (event) => {
       const { auth } = this.props.stores
       this.setState({loading: true})
       if(this.state.userName && this.state.Password){
           auth.signIn(this.state.userName, this.state.Password).then( async (result) => {
                if(result){
                    switch (true) {
                        case result.hasOwnProperty('error'):
                            //login error username or password rejected
                            this.setState({message: this.msg(result.error), error: true, loading: false})
                            break;
                        case result.hasOwnProperty('token'):
                            //login success

                            break;
                        default:
                            //unknown error something unexpected was return from api
                            this.setState({message: 'Something unexpected was return', error: true,loading: false})
                    }
                } else {
                    //couldnt connect to the API server
                    this.setState({message: 'Server Error cannot connect to server', error: false,loading: false})
                }
           })
       } else {
           //fields are empty
           this.setState({message: 'Fields cannot be empty', error: true,loading: false})
       }
    }

    render(){
      const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
             <div className={classes.paper}>
                 <img src={LogoThePubGroup} alt="Logo" width={"80%"}/>
               <Avatar className={classes.avatar}>
                 <LockOutlinedIcon />
               </Avatar>
               <Typography component="h1" variant="h5">

               </Typography>
               <Typography color="error">{this.state.message}</Typography>

                 <Grid container>
                   <Grid item xs>
                     <br />
                   </Grid>
                 </Grid>
                 <Button
                   type="submit"
                   fullWidth
                   variant="contained"
                   color="primary"
                   className={classes.submit}
                   disabled={this.state.loading}
                   onClick={this.azureSignIn}
                 >
                   Sign In
                 </Button>
                 <Grid container>
                   <Grid item xs>
                   </Grid>
                   <Grid item>
                   </Grid>
                 </Grid>

             </div>
              <Box mt={8}>
              </Box>
             {this._handleRedirection()}
           </Container>
        )
    }
}));

export default withStyles(styles)(Login);
