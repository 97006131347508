import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const AuthCallback = () => {
  const { instance } = useMsal();
  const history = useHistory();

  useEffect(() => {
    instance.handleRedirectPromise().then( async (response) => {
      if (response) {
        localStorage.setItem('msalToken', response.accessToken);
        console.log('accessToken', response.accessToken);
        //history.push('/dashboard'); // Redirect to your desired route
        const user = await getUserInfo(response.accessToken);
        console.log(user);

      }
    }).catch(error => {
      console.error(error);
    });
  }, [instance, history]);

  return <div>Loading...</div>;
};

const getUserInfo = async (accessToken) => {
  try {
    const response = await fetch('https://graph.microsoft.com/v1.0/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};

export default AuthCallback;
