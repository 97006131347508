import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from "@mui/material/Drawer";
import { makeStyles } from '@mui/styles';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import useStores from "../../stores/mobxProviderContext";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NewcodeLogo from "../../images/Logo-W.png"
import {withRouter} from "react-router-dom";
import {useEffect} from "react";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    account_circle: {
        fontSize: 100,
        margin: theme.spacing(2),
        color: "#2D2D2B"
    },
    account_circle_div: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 240
    },
    drawerDiv: {
        width: 100
    },
    drawer: {
        display: "flex",
        width: drawerWidth,
        flex: 1,
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    title: {
        marginLeft: 25,
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    appBar: {
        position: 'stickyHeader',
        zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
        maxWidth: 135,
        marginBottom: 10,
        marginLeft: 30,
        marginRight: 30
    },
    trading_name: {
        marginLeft: 10
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    newcode: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-end",
    }
}));
function ButtonAppBar(props) {
    const classes = useStyles();
    const {stores} = useStores();
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('The Pub Group');

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(!open);
    };

    useEffect(() => {
        const state = props.history.location.state;
        if(state && props.history.location.state.hasOwnProperty("venue")){
            setTitle(props.history.location.state.venue);
        }
    }, [])

    const handleNext = (page) => {
        setTitle(page.state.venue);
        props.history.push(page);
        setOpen(false);
    }

    const { auth } = stores;
    const user = auth.authUser
    return (
        <React.Fragment>
            <AppBar position="sticky" color={'secondary'} style={{position: 'sticky' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer()}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                anchor={'left'}
                open={open}
                onClose={toggleDrawer()}
            >
                <div className={classes.drawer}>
                    <div className={classes.account_circle_div}>
                        <Icon className={classes.account_circle} fontSize={"1px"}>account_circle</Icon>
                        <Typography variant={"caption"}>{user.user.UserFirstName} {user.user.UserLastName}</Typography>
                        <Typography variant={"caption"}>{user.user.UserEmailID}</Typography>
                    </div>
                    <Divider />
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton onClick={ () => handleNext({
                            pathname: '/Home',
                            state: { venue: "The Pub Group"}
                        })}>
                            <ListItemIcon>
                                <DashboardIcon style={{color:'#36454F' }} />
                            </ListItemIcon>
                        <ListItemText primary="TPG Hotels" />

                        </ListItemButton>
                        <ListItemButton onClick={ () => handleNext({
                            pathname: '/Venue',
                            state: { venue: "Pub"}
                        })}>
                            <ListItemIcon>
                                <DashboardIcon style={{color:'#5d6554' }} />
                            </ListItemIcon>
                            <ListItemText primary="The Pub" />

                        </ListItemButton>
                        <ListItemButton onClick={ () => handleNext({
                            pathname: '/Venue',
                            state: { venue: "Southgate"}
                        })}>
                            <ListItemIcon>
                                <DashboardIcon style={{color:'#536672' }}/>
                            </ListItemIcon>
                            <ListItemText primary="Southgate Inn" />
                        </ListItemButton>
                        <ListItemButton onClick={ () => handleNext({
                            pathname: '/Venue',
                            state: { venue: "Family"}
                        })}>
                            <ListItemIcon>
                                <DashboardIcon style={{color:'#7a5660' }} />
                            </ListItemIcon>
                            <ListItemText primary="Family Hotel" />
                        </ListItemButton>
                        <ListItemButton onClick={ () => handleNext({
                            pathname: '/Venue',
                            state: { venue: "Longyard"}
                        })}>
                            <ListItemIcon>
                                <DashboardIcon style={{color:'#bb935e' }}/>
                            </ListItemIcon>
                            <ListItemText primary="Longyard Hotel" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton onClick={() => stores.auth.SignOut()} >
                                <ListItemIcon><Icon>power_settings_new</Icon></ListItemIcon>
                                <ListItemText primary={"Sign Out"} />
                        </ListItemButton>
                    </List>
                    <div className={classes.newcode}>
                        <Divider />
                        <Typography style={{color: '#2D2D2B', margin:15}} variant={"caption"}>Newcode Technology Pty Ltd</Typography>
                        <div style={{backgroundColor: "#2D2D2B", padding: 20}}>
                            <img src={NewcodeLogo} alt="Logo" width={"50%"}/>
                            <br />
                            <br />
                            <Typography style={{color: '#FFF'}} variant={"caption"}>For more App like this!</Typography>
                        </div>
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    );
}

export default withRouter(ButtonAppBar);
