import React, { PureComponent } from "react";
import ReactEcharts from "echarts-for-react";

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function formatTip (params) {

    if(params[0].data.profit){
        return (
            params[0].name +": " +
            "<br />Turnover: " + currencyFormat(parseFloat(params[0].value)) +
            "<br />Profit: "  + currencyFormat(parseFloat(params[0].data.profit))
        );
    } else {
        return (
            params[0].name +": " +
            "<br />Profit: " + currencyFormat(parseFloat(params[0].value))
        );
    }

}

class BarH extends PureComponent {
    getOption = () => ({
        title: {
            text: this.props.title,
            subtext: this.props.subtext,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: 'shadow'
            },
            formatter: formatTip
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],

        },
        yAxis: {
            type: "category",
            data: this.props.salesData.l
        },
        series: [
            {
                //name: "邮件营销",
                type: "bar",
                data: this.props.salesData.v,
                animationDuration: 5000
            }
        ]
    });

    render() {
        return (
            <ReactEcharts
                option={this.getOption()}
                style={{ height: this.props.height, width: "100%" }}
            />
        );
    }
}
export default BarH;
