import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'mobx-react';
import stores from './stores';
import Login from './components/user/Login';
import NotFound from './components/NotFound';
import { observer } from 'mobx-react';
import HomeView from './components/home/HomeScreen';
import VenueScreen from "./components/home/VenueScreen";
import Logout from './components/user/Logout';
import { withStyles } from '@mui/styles';
import green from '@mui/material/colors/green';
import history from './stores/history';
import AppBar from './components/shared/appBar';
import MomentUtils from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import purple from "@mui/material/colors/purple";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AuthCallback from "./auth/callback";
import {MsalProvider} from "@azure/msal-react";
import msalInstance from "./auth/msalConfig";


const styles = theme => ({
    FoldersRoot: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
    },
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#E7DFD4'
        },
        secondary: {
            main: '#2D2D2B'
        }
    },
    status: {
        danger: 'deepOrange',
    },
});

const GlobalStore = React.createContext(stores);

let App = observer(
class App extends Component {

    LoginContainer = () => {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path="/" component={Login}/>
                    <Route exact path="/Login" component={Login}/>
                    <Route exact path="/auth/callback" component={AuthCallback} />
                </Switch>
            </React.Fragment>
        )
    }

    DefaultContainer = () => {
        const {classes} =this.props
        return (
            <div className={classes.FoldersRoot}>
                <AppBar history={history}/>
                <Switch>
                    <PrivateRoute exact path="/Home" component={HomeView}/>
                    <PrivateRoute exact path="/Venue" component={VenueScreen}/>
                    <PrivateRoute exact path="/Logout" component={Logout}/>
                    <PrivateRoute component={NotFound}/>
                </Switch>
            </div>
        )
    }

    render() {
        return (

            <GlobalStore.Provider value={stores}>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={MomentUtils}>
                        <Router history={history}>
                            <Provider stores={stores} >
                                <MsalProvider instance={msalInstance}>
                                    <Switch>
                                        <Route exact path="/" component={this.LoginContainer}/>
                                        <Route exact path="/Login" component={this.LoginContainer}/>
                                        <PrivateRoute component={this.DefaultContainer}/>
                                    </Switch>
                                </MsalProvider>
                            </Provider>
                        </Router>
                    </LocalizationProvider>
                </ThemeProvider>
            </GlobalStore.Provider>

        );
    }
});

const PrivateRoute = observer(({ component: Component, ...rest }) => {
    const authUser = stores.auth.getAuthUser
    if(authUser){
        if(authUser.hasOwnProperty('token')){
            return (
                <Route {...rest} render={(props) => (
                    <Component {...props} />
                )}/>
            )
        }
    }

    return (
        <Route {...rest} render={(props) => (
            <Redirect to={{
                pathname: "/Login",
                state: {from: props.location}
            }}
            />
        )}/>
    )

})

export default withStyles(styles)(App);
