import React, { PureComponent } from "react";
import ReactEcharts from "echarts-for-react";

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function formatTip (params) {
    return params.name +" : "+ currencyFormat(parseFloat(params.value));
}

class Doughnut extends PureComponent {
    getOption = () => ({
        title: {
            text: this.props.title,
            subtext: this.props.subtext,
        },
        tooltip: {
            trigger: 'item',
            formatter: formatTip
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        legend: {
                top: "85%",
                left: 0,
                zlevel: 0,
                z: 4,
                show: true,
                orient: "vertical",
                align: "auto",
                backgroundColor: "rgba(0,0,0,0)",
                borderColor: "#ccc",
                borderRadius: 0,
                borderWidth: 0,
                padding: 5,
                itemGap: 10,
                itemWidth: 25,
                itemHeight: 14,
                symbolRotate: "inherit",
                symbolKeepAspect: true,
                inactiveColor: "#ccc",
                inactiveBorderColor: "#ccc",
                inactiveBorderWidth: "auto"
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 8,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: this.props.salesData,
                color: this.props.colors
            }
        ]
    });

    render() {
        return (
            <ReactEcharts
                option={this.getOption()}
                style={{ height: this.props.height, width: "100%" }}
            />
        );
    }
}
export default Doughnut;
