import {observable, action,makeObservable} from 'mobx';
import FetchStore from './fetch';
import config from "./config";

export default class UserStore extends FetchStore {

    constructor(props){
        super(props)
        this._retrieveData()
        makeObservable(this, {
            authUser: observable,
            signIn: action,
            SignOut: action,
        })
    }

    authUser = null;

    get getAuthUser() {
        return this.authUser
    }

    set setAuthUser(user) {
        this.authUser = user
    }

    _retrieveData = () => {
        try {
            const data = sessionStorage.getItem('@USER_DATA');
            if (data !== null) {
                this.setAuthUser = JSON.parse(data)
                this.storeUserApiKey(this.getAuthUser.token)
                return this.getAuthUser
            }
        } catch (error) {
            // Error retrieving data
        }
    }

    azureSignIn = async (token) => {
        try {
            let response = await fetch(`${config.authDomain}azureAuth.php`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    "Content-type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({token: token})
            })
            const user =  await response.json();

            if(user) {
                if(user.hasOwnProperty('error')){
                    return user;
                }

                if(user.hasOwnProperty('token')){
                    sessionStorage.setItem('@USER_DATA', JSON.stringify(user));
                    await this.storeUserApiKey(user.token);
                    this.setAuthUser = user;
                }
                return user
            }
            return null
        } catch(error) {
            console.error(error);
        }
    }

    signIn = async (username, password) => {
        const data = {
            "username" : username,
            "password" : password
        }
        const user = await this.postToApi(JSON.stringify(data),"login.php")
        if(user) {
            if(user.hasOwnProperty('error')){
                return user;
            }

            if(user.hasOwnProperty('token')){
                sessionStorage.setItem('@USER_DATA', JSON.stringify(user));
                await this.storeUserApiKey(user.token);
                this.setAuthUser = user;
            }
            return user
        }
        return null
    }

    static SignOut() {
        this.setAuthUser = null;
        sessionStorage.clear();
    }

    SignOut() {
        this.setAuthUser = null;
        sessionStorage.clear();
        return this.getAuthUser;
    }

}
