import React, { Component }from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

let Logout = inject("stores")(
    observer(
class Logout extends Component {

    componentDidMount = async () => {
        const { auth } = this.props.stores
        auth.SignOut();
    }

    render(){
      const { auth } = this.props.stores
      const user = auth.getAuthUser;

        if(!user){
            return <Redirect to={'./Login'} />
        }

        return (
            <LinearProgress color="secondary" />
        )
    }
}));

export default Logout;
