import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import useStores from "../../stores/mobxProviderContext";
import Loading from "../shared/loading";
import Pie from "../shared/pieChart";
import BarH from "../shared/barChartH";
import Doughnut from "../shared/doughnut";
import DenseTable from "./HomeTable";
import TextField from "@mui/material/TextField";
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Moment from 'moment';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {Paper, Typography} from "@mui/material";
import BarQty from "../shared/barChartQty";


function sumTotal(items, prop){
    return items.reduce( function(a, b){
        return parseFloat(a) + parseFloat(b[prop]);
    }, 0);
};

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const myStyles = makeStyles((theme) => ({
    containerItem: {
        backgroundColor: "whitesmoke",
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2)
    },
    containerItemButtons: {
        backgroundColor: "whitesmoke",
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(1),
        justifyContent: "center",
        alignItems: 'center'
    },
    root: {
        backgroundColor: "whitesmoke",
    },
}));

const VenueScreen = (props) => {
    const classes = myStyles();
    const {stores} = useStores();
    const {data} = stores;
    const [salesData, setSalesData] = useState({});
    const [gamingData, setGamingData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [value, setValue] = React.useState([Moment(),Moment()]);
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [selected, setSelected] = useState("today");

    const currentVenue = props.location.state.venue;

    const setDateValue = v => {
        if( v[0] && v[1] ) {
            if(value[0] && value[1]){
                if(v[0].format("YYYY-MM-DD") !== value[0].format("YYYY-MM-DD") || v[1].format("YYYY-MM-DD") !== value[1].format("YYYY-MM-DD")){
                    setValue(v);
                }
            } else {
                setValue(v);
            }
        }
    };

    const setDateNewValue = e => {
        switch(e.target.name) {
            case "today":
                setValue([Moment(),Moment()]);
                setSelected("today");
                break;
            case "yesterday":
                setValue([Moment().subtract(1,'days'),Moment().subtract(1,'days')]);
                setSelected("yesterday");
                break;
            case "thisWeek":
                setValue([Moment().startOf('week').add(1, 'days'),Moment()]);
                setSelected("thisWeek");
                break;
            case "lastWeek":
                setValue([Moment().startOf('week').subtract(6,'days'),Moment().startOf('week')]);
                setSelected("lastWeek");
                break;
            default:
            // code block
        }
    };

    useEffect(() => {
        const load = async () => {
            let res = await data.getVenueData();
            let gamingResult = await data.getVenueGamingData(currentVenue);
            if (!res.hasOwnProperty('error')) {
                if (Array.isArray(res[currentVenue]) && res[currentVenue].length) {
                    setSalesData(res);
                    setGamingData(gamingResult);
                    setDataLoaded(true);
                    setLoading(false);
                    setLoadingMessage('')
                } else {
                    setLoadingMessage('No data! Please check connection.')
                }
            }
        }
        const loadMore = async () => {
            let res = await data.getVenueData(value[0].format("YYYY-MM-DD"),value[1].format("YYYY-MM-DD"));
            let gamingResult = await data.getVenueGamingData(currentVenue,value[0].format("YYYY-MM-DD"),value[1].format("YYYY-MM-DD"));
            if (!res.hasOwnProperty('error')) {
                if (Array.isArray(res[currentVenue]) && res[currentVenue].length) {
                    setSalesData(res);
                    setGamingData(gamingResult);
                    setDataLoaded(true);
                    setLoading(false);
                    setLoadingMessage('')
                } else {
                    setLoadingMessage('No data! Please check connection.')
                }
            }
        }

        setLoading(true);
        setLoadingMessage('Loading...')
        if(value[0] && value[1]){
            loadMore()
        } else {
            load();
        }
    }, [currentVenue,data,value])

    if (dataLoaded === false) {
        return <Loading message={loadingMessage}/>
    } else if(Array.isArray(salesData[currentVenue]) && salesData[currentVenue].length === 0){
        return <Loading message={loadingMessage}/>
    } else {
        const colors = ['#fe4a49','#2ab7ca','#fed766','#f37736','#7bc043','#012232','#0000FF','#808000','#800000'];
        let barData = [{value: '', name: ''}];
        let pieData = [{name: '', value: 0, color: '#fe4a49'}]
        let tableData = {"venue": []};
        let pokData = {v: [{value: 1,itemStyle: {color: colors[1]}}],l: ["l"]}
        let BsData = {v: [{value: 0,itemStyle: {color: colors[1]}}],l: ["l"]}
        let totalTurnover = 0;
        let totalProfit = 0;
        let totalProfitPokies = 0;
        let bsTotalProfit = 0;
        if (salesData && salesData.hasOwnProperty(currentVenue)) {
            let barSales = [{value: '', name: ''}];
            let pie = [{name: '', value: 0, color: '#fe4a49'}]

            tableData = {
                    [currentVenue]: salesData[currentVenue]
            };

            totalTurnover = currencyFormat(sumTotal(salesData[currentVenue],'AMOUNT'));
            totalProfit = currencyFormat(sumTotal(salesData[currentVenue],'NETT'));

            let pObj = salesData[currentVenue].find(item => item.Description === "POKIES");
            totalProfitPokies = currencyFormat(parseFloat(pObj['NETT']));

            barSales = salesData[currentVenue].map(item => ({value: item["NETT"], name: item["Description"]}));
            pie = salesData[currentVenue].map((item, i) => ({
                name: item["Description"],
                value: parseFloat(item['AMOUNT']),
                color: colors[i]
            }));

            pokData.v = gamingData["Gaming"].slice(Math.max(gamingData["Gaming"].length - 8, 0)).map((item, i) => ({
                name: item["Description"],
                profit: parseFloat(item['NETT']),
                value: parseFloat(item['AMOUNT']),
                itemStyle: {color: colors[i]}
            }));
            pokData.l = gamingData["Gaming"].slice(Math.max(gamingData["Gaming"].length - 8, 0)).map((item, i) => ([item["GMHouseNo"]]));

            if(gamingData["Products"].length > 0){
                BsData.v = gamingData["Products"].slice(0, 8).map((item, i) => ({
                    name: item["Description"],
                    value: parseFloat(item['Sales']),
                    itemStyle: {color: colors[i]}
                }));
                BsData.l = gamingData["Products"].slice(0, 8).map((item, i) => ([item["Description"].trim().replace("SCHOONER", "SCH").replace(/^(.+? .+?) \s*/g,'$1\n')]));
            }

            barData = barSales;
            pieData = pie;

        } else {

            return (<Paper elevation={3} ><Typography variant="subtitle2" component="h2">
                You dont have Permission to this venue
            </Typography></Paper>)
        }

        return (
            <Grid container className={classes.root} spacing={0} marginBottom={10}>
                {loading === true ?
                    <Box sx={{ width: '100%', position: 'fixed'}}>
                        <LinearProgress color={"error"}/>
                    </Box>
                    : null}
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <DateRangePicker
                                disableFuture={true}
                                value={value}
                                clearable={true}
                                inputFormat="DD/MM/YYYY"
                                //maxDate={getWeeksAfter(value[0], 4)}
                                onChange={(newValue) => {
                                    setDateValue(newValue);
                                }}
                                //onClose={ () => alert("close")}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItemButtons}>
                            <div className={classes.buttonsItem}>
                                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                    <Button onClick={setDateNewValue} name={"today"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "today" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "today" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                        Today
                                    </Button>
                                    <Button onClick={setDateNewValue} name={"yesterday"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "yesterday" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "yesterday" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                        Yesterday
                                    </Button>
                                    <Button onClick={setDateNewValue} name={"thisWeek"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "thisWeek" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "thisWeek" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                        ThisWeek
                                    </Button>
                                    <Button onClick={setDateNewValue} name={"lastWeek"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "lastWeek" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "lastWeek" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                        LastWeek
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <Pie title={"Venues Turnover"} salesData={pieData} colors={colors} subtext={totalTurnover}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <BarQty title={"Top Bar Products"} salesData={BsData} subtext={bsTotalProfit}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <BarH title={"Top Gaming Machines"} salesData={pokData} subtext={"By Turnover - Total Profit: " + totalProfitPokies} height={350}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <Doughnut title={"Venue Profit"} salesData={barData} colors={colors} subtext={totalProfit} height={350}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <DenseTable title={"Venues Turnover"} salesData={tableData}/>
                    </Grid>
                </Grid>

            </Grid>
        )
    }
}

export default VenueScreen;
