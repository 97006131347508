import config from './config'

class FetchStore {

    constructor(){
        this.config = config
    }

    storeUserApiKey = async (key) => {
        sessionStorage.setItem('@USER_API_KEY', key)
    }

    postToApi = async (data, url) => {
        const key = sessionStorage.getItem('@USER_API_KEY');
        try {
            let response = await fetch(`${config.authDomain}${url}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    "Content-type": "application/json",
                    'Authorization': `Bearer ${key}`
                },
                body: data
            })
            let responseJson = await response.json();
            //console.log(responseJson)

            if(responseJson && responseJson.hasOwnProperty('error')){
                if(responseJson.error === 'Expired token'){
                    //console.log("TokenExpiredError Go to Login");
                    sessionStorage.clear();
                    window.location.reload();
                }
            }

            return responseJson;
        } catch(error) {
            console.error(error);
        }
    }

    putToApi = async (data, url) => {
        const key = sessionStorage.getItem('@USER_API_KEY');
        try {
            let response = await fetch(`${config.authDomain}${url}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    "Content-type": "application/json",
                    'Authorization': `Bearer ${key}`
                },
                body: data
            })
            let responseJson = await response.json();
            //console.log(responseJson)

            if(responseJson && responseJson.hasOwnProperty('error')){
                if(responseJson.error === 'Expired token'){
                    //console.log("TokenExpiredError Go to Login");
                    sessionStorage.clear();
                    window.location.reload();
                }
            }

            return responseJson;
        } catch(error) {
            console.error(error);
        }
    }

    deleteToApi = async (data, url) => {
        const key = sessionStorage.getItem('@USER_API_KEY');
        try {
            let response = await fetch(`${config.authDomain}${url}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    "Content-type": "application/json",
                    'Authorization': `Bearer ${key}`
                },
                body: data
            })
            let responseJson = await response.json();
            //console.log(responseJson)

            if(responseJson && responseJson.hasOwnProperty('error')){
                if(responseJson.error === 'Expired token'){
                    //console.log("TokenExpiredError Go to Login");
                    sessionStorage.clear();
                    window.location.reload();
                }
            }

            return responseJson;
        } catch(error) {
            console.error(error);
        }
    }



    getFromApi = async (data, url) => {
        const key = sessionStorage.getItem('@USER_API_KEY');
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + key);
        myHeaders.append("Accept", "application/json" );
        myHeaders.append("Content-type", "application/x-www-form-urlencoded" );

        let requestOptions = {
            method: 'GET',
            headers: myHeaders
        };

        try {
            let response = await fetch(`${config.authDomain}${url}?`+ data,requestOptions);
                let responseJson = await response.json();
            //console.log(responseJson);

            if(responseJson && responseJson.hasOwnProperty('error')){
                if(responseJson.error === 'Expired token'){
                    //console.log("TokenExpiredError Go to Login");
                    sessionStorage.clear();
                    window.location.reload();
                }
            }

            return responseJson;
        } catch(error) {
            console.error(error);
        }
    }
}

export default FetchStore;
