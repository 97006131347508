import React, { PureComponent } from "react";
import ReactEcharts from "echarts-for-react";

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function formatTip (params) {
    return params[0].name +" : "+ currencyFormat(parseFloat(params[0].value));
}

class Bar extends PureComponent {
    getOption = () => ({
        title: {
            text: this.props.title,
            subtext: this.props.subtext,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: 'shadow'
            },
            formatter: formatTip
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: "category",
            data: this.props.salesData.l
        },
        yAxis: {
            type: "value"
        },
        series: [
            {
                //name: "邮件营销",
                type: "bar",
                data: this.props.salesData.v,
                animationDuration: 5000,
            }
        ]
    });

    render() {
        return (
            <ReactEcharts
                option={this.getOption()}
                style={{ height: "280px", width: "100%" }}
            />
        );
    }
}
export default Bar;
