import msalInstance from './msalConfig';
import config from "../stores/config";

const loginRequest = {
  scopes: ["user.read"]
};

const login = async () => {
  try {
    const response = await msalInstance.loginPopup(loginRequest)
    // Handle login success (e.g., send the token to your backend)
    return response.accessToken;
  }
  catch(error) {
    console.error(error);
  }
};

const getUserInfo = async (accessToken) => {
  try {
    const response = await fetch('https://graph.microsoft.com/v1.0/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user info:", error);
  }
};

export default login;
