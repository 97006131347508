import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import useStores from "../../stores/mobxProviderContext";
import Loading from "../shared/loading";
import BarChart from "../shared/barChart";
import Pie from "../shared/pie";
import BarH from "../shared/barChartH";
import Doughnut from "../shared/doughnut";
import DenseTable from "./HomeTable";
import TextField from "@mui/material/TextField";
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Moment from 'moment';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

function sumTotal(items, prop){
    return items.reduce( function(a, b){
        return parseFloat(a) + parseFloat(b[prop]);
    }, 0);
};

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const myStyles = makeStyles((theme) => ({
    containerItem: {
        backgroundColor: "whitesmoke",
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2)
    },
    containerItemButtons: {
        backgroundColor: "whitesmoke",
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(1),
        justifyContent: "center",
        alignItems: 'center'
    },
    root: {
        backgroundColor: "whitesmoke",
    },
}));

const HomeScreen = (props) => {
    const classes = myStyles();
    const {stores} = useStores();
    const {data} = stores;
    const [salesData, setSalesData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [value, setValue] = React.useState([Moment(),Moment()]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState("today");

    const setDateValue = v => {
        if( v[0] && v[1] ) {
            if(value[0] && value[1]){
                if(v[0].format("YYYY-MM-DD") !== value[0].format("YYYY-MM-DD") || v[1].format("YYYY-MM-DD") !== value[1].format("YYYY-MM-DD")){
                    setValue(v);
                }
            } else {
                setValue(v);
            }
        }
    };

    const setDateNewValue = e => {
        switch(e.target.name) {
        case "today":
            setValue([Moment(),Moment()]);
            setSelected("today");
            break;
        case "yesterday":
            setValue([Moment().subtract(1,'days'),Moment().subtract(1,'days')]);
            setSelected("yesterday");
            break;
        case "thisWeek":
            setValue([Moment().startOf('week').add(1, 'days'),Moment()]);
            setSelected("thisWeek");
            break;
        case "lastWeek":
            setValue([Moment().startOf('week').subtract(6,'days'),Moment().startOf('week')]);
            setSelected("lastWeek");
            break;
        default:
            // code block
        }
    };

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            let res = await data.getAllData();
            if (!res.hasOwnProperty('error')) {
                setSalesData(res);
                setDataLoaded(true);
                setLoading(false);
            }
        }
        const loadMore = async () => {
            setLoading(true);
            let res = await data.getAllData(value[0].format("YYYY-MM-DD"),value[1].format("YYYY-MM-DD"));
            if (!res.hasOwnProperty('error')) {
                setSalesData(res);
                setDataLoaded(true);
                setLoading(false);
            }
        }
        if(value[0] && value[1]){
            loadMore()
        } else {
            load();
        }
    }, [data,value])

    if (dataLoaded === false) {
        return <Loading />
    } else {
        const pokData = {};
        const BsData = {};
        let barData = {};
        const colors = ['#5d6554','#536672','#7a5660','#bb935e'];
        let totalTurnover = null;
        let pokieTotalProfit = null;
        let bsTotalProfit = null;
        let barTotalProfit = null;
        let tableData = {};
        if (salesData && Object.keys(salesData).length > 0){
            let pokieValue = [];
            let bsValues = []
            let labels = [];
            let bslabels = [];
            let turnover = [];
            let barSales = [];

            Object.keys(salesData).forEach(function(key, index) {
                if(salesData[key].length === 0 ) {
                    return;
                }

                tableData = {
                    ...tableData,
                    [key]: salesData[key]
                };

                let pObj = salesData[key].find(item => item.Description === "POKIES");
                let bObj = salesData[key].find(item => item.Description === "BOTTLE SHOP");
                let barObj = salesData[key].find(item => item.Description === "BEVERAGE");

                turnover.push(sumTotal(salesData[key],'AMOUNT'));

                if(barObj && barObj.hasOwnProperty("NETT")){
                    barSales.push({
                        value: barObj["NETT"],
                        name: key
                    });
                }

                if(pObj && pObj.hasOwnProperty("NETT")){
                    pokieValue.push({
                        value: pObj["NETT"],
                        itemStyle: {
                            color: colors[index]
                        }
                    });
                    labels.push(key);
                }
                if(bObj && bObj.hasOwnProperty("NETT")){
                    bsValues.push({
                        value: bObj["NETT"],
                        itemStyle: {
                            color: colors[index]
                        }
                    });
                    bslabels.push(key);
                }
            });

            totalTurnover = currencyFormat(turnover.reduce((a, b) => {
                return a + b;
            }));

            pokieTotalProfit = currencyFormat(sumTotal(pokieValue, "value"));
            bsTotalProfit = currencyFormat(sumTotal(bsValues, "value"));
            barTotalProfit = currencyFormat(sumTotal(barSales, "value"));
            pokData.v = pokieValue;
            pokData.l = labels;
            BsData.v = bsValues;
            BsData.l = bslabels;
            barData = barSales;
        }

        return (
            <Grid container className={classes.root} spacing={0} marginBottom={10}>

                {loading === true ?
                    <Box sx={{ width: '100%', position: 'fixed'}}>
                        <LinearProgress color={"error"}/>
                    </Box>
                    : null}

                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <DateRangePicker
                                disableFuture={true}
                                value={value}
                                clearable={true}
                                inputFormat="DD/MM/YYYY"
                                //maxDate={getWeeksAfter(value[0], 4)}
                                onChange={(newValue) => {
                                    setDateValue(newValue);
                                }}
                                //onClose={ () => alert("close")}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItemButtons}>
                            <div className={classes.buttonsItem}>
                                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                    <Button onClick={setDateNewValue} name={"today"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "today" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "today" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                        Today
                                    </Button>
                                    <Button onClick={setDateNewValue} name={"yesterday"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "yesterday" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "yesterday" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                        Yesterday
                                    </Button>
                                    <Button onClick={setDateNewValue} name={"thisWeek"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "thisWeek" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "thisWeek" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                         ThisWeek
                                    </Button>
                                    <Button onClick={setDateNewValue} name={"lastWeek"} variant="contained" size="small"
                                            style={{
                                                backgroundColor: selected === "lastWeek" ? "#E7DFD4" : '#2D2D2B',
                                                color: selected === "lastWeek" ?  '#2D2D2B' : "#E7DFD4"
                                            }}>
                                        LastWeek
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <Pie title={"Venues Turnover"} salesData={salesData} colors={colors} subtext={totalTurnover}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <BarH title={"Pokies Profit"} salesData={pokData} subtext={pokieTotalProfit} height={280}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <BarChart title={"Bottle Shop Profit"} salesData={BsData} subtext={bsTotalProfit}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item className={classes.containerItem}>
                            <Doughnut title={"Bar Profit"} salesData={barData} colors={colors} subtext={barTotalProfit} height={280}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <DenseTable title={"Venues Turnover"} salesData={tableData}/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default HomeScreen;
