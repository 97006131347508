import * as React from "react";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        containerItem: {
            padding: theme.spacing(25),
            backgroundColor: "red"
        }
    })
);

export default function Component() {
    const classes = useStyles();
    return <div className={classes.containerItem}></div>;
}
