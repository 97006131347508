import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num){
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

let gptmp = [];
function gpPercentage(a,n) {
    let amount_ex_gst = (parseFloat(a) / 1.1);
    let gp = numberFormat(parseFloat(n) / amount_ex_gst * 100);
    gptmp.push(gp);
    return gp;
}

function getGpTotal(){
    let gp = gptmp;
    gptmp = [];
    let count = gp.length;
    gp = gp.reduce((a, b) => {
        return parseFloat(a) + parseFloat(b);
    });
    return numberFormat(gp / count);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E7DFD4",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2D2D2B",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function sumTotal(items, prop){
    return items.reduce( function(a, b){
        return parseFloat(a) + parseFloat(b[prop]);
    }, 0);
};

export default function DenseTable(props) {
    const { salesData } = props
    let arr = [];
    let names = [];
    Object.keys(salesData).forEach(function(key) {
        arr.push(salesData[key]);
        names.push(key)
    });
    return (
        <TableContainer component={Paper}>
            {arr.map((r, index) => (
                <Table key={index} sx={{ Width: "100%" }} style={{width: "100%",tableLayout: 'fixed',whiteSpace: 'nowrap'}}
                       size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{names[index]}</StyledTableCell>
                            <StyledTableCell align="right">Sales</StyledTableCell>
                            <StyledTableCell align="right">GP$</StyledTableCell>
                            <StyledTableCell style={{width: 30}} align="right">GP%</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {r.map((row) => (
                            <StyledTableRow
                                hover
                                key={row.Description}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell  style={{fontSize: 10}} component="th" scope="row">{row.Description}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormat(parseFloat(row.AMOUNT))}</StyledTableCell>
                                <StyledTableCell align="right">{currencyFormat(parseFloat(row.NETT))}</StyledTableCell>
                                <StyledTableCell style={{width: 30}} align="right">{gpPercentage(row.AMOUNT,row.NETT)}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        <StyledTableRow>
                            <StyledTableCell style={{fontSize: 14,fontWeight: "bold"}} component="th" scope="row">Total</StyledTableCell>
                            <StyledTableCell style={{fontWeight: "bold"}}align="right">{currencyFormat(sumTotal(r, "AMOUNT"))}</StyledTableCell>
                            <StyledTableCell style={{fontWeight: "bold"}}align="right">{currencyFormat(sumTotal(r, "NETT"))}</StyledTableCell>
                            <StyledTableCell style={{width: 30,fontWeight: "bold"}} align="right">{
                                getGpTotal()

                            }</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            ))}
        </TableContainer>
    );
}
