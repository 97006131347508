import FetchStore from './fetch';

export default class DataStore extends FetchStore {

    getAllData = async (from = null, to= null ) => {
        const endpoint = `getVenueSalesData.php`;
        let data = ""
        if(from !== null || to !== null)
        data = `from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`
        const result = await this.getFromApi(data,endpoint);
        //console.log(result);
        return result;
    }

    getVenueData = async (from = null, to= null ) => {
        const endpoint = `getVenueSalesData.php`;
        let data = ""
        if(from !== null || to !== null)
            data = `from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`
        const result = await this.getFromApi(data,endpoint);
        //console.log(result);
        return result;
    }

    getVenueGamingData = async (venue, from = null, to= null ) => {
        const endpoint = `getVenueGamingData.php`;
        let data = `venue=${encodeURIComponent(venue)}`
        if(from !== null || to !== null)
            data = `venue=${encodeURIComponent(venue)}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`
        const result = await this.getFromApi(data,endpoint);
        //console.log(result);
        return result;
    }

}
