import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Component from "./test2";

const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: purple[500]
        },
        secondary: {
            // This is green.A700 as hex.
            main: "#11cb5f"
        }
    }
});

export default function Palette() {
    return (
        <ThemeProvider theme={theme}>
            <Button>Primary</Button>
            <Button color="secondary">Secondary</Button>
            <Component />
        </ThemeProvider>
    );
}
