import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '17dca5d5-3ef7-48b4-9725-cd26c70a4a85',
    authority: `https://login.microsoftonline.com/a0e93093-c6a4-4fdd-a1c0-d5557d0d51ce`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["User.Read"]
};


export default msalInstance;
